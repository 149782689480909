import { toEstimate } from "../Shared/SiteCommon";

namespace DansaiSiteCommon {
    var siteId = 4;

    jQuery(function () {
        replaceForm();
        setupEstimateButton();
        searchReplace();
    });

    function searchReplace() {
        jQuery("form[action='https://www.dansai.jp/product-list']").submit(function () {
            event.preventDefault();
            var sk = jQuery("input[name=keyword]").val();
            var url1 = "https://www.workup-system.com/Api/SearchKey/ReplaceSearchKey?key=" + encodeURIComponent(sk);
            jQuery.ajax({
                type: "GET",
                url: url1,
                dataType: "json",
                async: false,
            }).then(function (src) {
                window.location.href = "https://www.dansai.jp/product-list?keyword=" + encodeURIComponent(src);
            });
            var url = "https://www.workup-system.com/api/SearchKey/RegSearchKey?siteInfoId=4&key=" + encodeURIComponent(sk);
            jQuery.get(url);
        });
    }

    function replaceForm() {
        if (location.href.indexOf('/contact') != -1) {
            location.href = 'https://www.dansai.jp/page/20';
        }

        if (location.href.indexOf('https://daishi.ocnk.net/contact') != -1) {
            location.href = 'https://www.dansai.jp/page/20';
        }

        var alist = jQuery("a[href^='https://daishi.ocnk.net/contact']");
        alist.attr("href", 'https://daishi.ocnk.net/page/20?from=top');

        var forms = jQuery("form[action^='https://daishi.ocnk.net/contact']");
        forms.attr("action", 'https://daishi.ocnk.net/page/20');


        var contactInput = jQuery("input[value='お問い合わせ']");
        contactInput.attr("onClick", 'jumpContact()');
    }


    function jumpContact() {
        location.replace('https://www.dansai.jp/page/20?from=product');
        return false;
    }


    function setupEstimateButton() {
        //カートの最初のページのみ発動
        if (document.getElementById("shoppingcart_page_step0") != null) {
            const form = jQuery("#register");
            const formBtns = form.find(".form_btn");
            const lastElem = formBtns.last();
            lastElem.append(
                `<span class="global_btn registerinput_btn">
    <button type="button" id="estimate" class="registerinput btn_size_xxlarge btn_color_emphasis">
        <span class="button_head_space"></span>
        <span class="button_text">見積書発行</span>
        <span class="button_end_space"></span>
    </button>
</span>`);
            const estBtn = document.getElementById("estimate");
            estBtn.onclick = <any>((a, b) => {
                toEstimate(siteId);
            });
        }
    }

} 